import { RouteInfo } from './sidebar.metadata';
import { Role } from 'src/app/shared/security/role';
export const ROUTES: RouteInfo[] = [


  // Refferer Modules

  // Admin Modules
  {
    path: '/admin/dashboard',
    title: 'Dashboard',
    moduleName: 'dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },

  {
    path: '',
    title: 'Admin',
    moduleName: 'AdminmanagmentModule',
    iconType: 'material-icons-two-tone',
    icon: 'supervised_user_circle',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/adminuser/list-all',
        title: 'List All',
        moduleName: 'AdminmanagmentModule',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/admin/adminuser/add-new',
        title: 'Add New',
        moduleName: 'AdminmanagmentModule',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'Surgeon',
    moduleName: 'DoctormangmentModule',
    iconType: 'material-icons-two-tone',
    icon: 'medical_services',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/doctor/list-all',
        title: 'List All',
        moduleName: 'DoctormangmentModule',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/admin/doctor/add-new',
        title: 'Add New',
        moduleName: 'DoctormangmentModule',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ],
  },
  // {
  //   path: '',
  //   title: 'Assistant',
  //   moduleName: 'AssitantmanagmentModule',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'supervised_user_circle',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/assitant/list-all',
  //       title: 'List All',
  //       moduleName: 'AssitantmanagmentModule',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/assitant/add-new',
  //       title: 'Add New',
  //       moduleName: 'AssitantmanagmentModule',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     }
  //   ],
  // },
  {
    path: '',
    title: 'Referring Doctors',
    moduleName: 'RefferermanagmentModule',
    iconType: 'material-icons-two-tone',
    icon: 'call_split',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/refferer/list-all',
        title: 'List All',
        moduleName: 'RefferermanagmentModule',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/admin/refferer/add-new',
        title: 'Add New',
        moduleName: 'RefferermanagmentModule',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ],
  },
    {
      path: '/admin/doctor/schedule',
      title: 'Master Call Schedule',
      moduleName: '',
      iconType: 'material-icons-two-tone',
      icon: 'supervised_user_circle',
      class: '',
      groupTitle: false,
      badge: '',
      badgeClass: '',
      role: ['Admin'],
      submenu: [],
    },

    // Refferal Module

    // {
    //   path: '/refferer/dashboard',
    //   title: 'Dashboard',
    //   moduleName: 'dashboard',
    //   iconType: 'material-icons-two-tone',
    //   icon: 'home',
    //   class: '',
    //   groupTitle: false,
    //   badge: '',
    //   badgeClass: '',
    //   role: ['Refferer'],
    //   submenu: [],
    // },
    {
      path: '/appointment/refferer',
      title: 'Appointment List',
      moduleName: 'ApointmentModule',
      iconType: 'material-icons-two-tone',
      icon: 'assignment',
      class: '',
      groupTitle: false,
      badge: '',
      badgeClass: '',
      role: ['Refferer'],
      submenu: [],
    },
    {
      path: '/appointment/add-new',
      title: 'Request Appointment',
      moduleName: 'ApointmentModule',
      iconType: 'material-icons-two-tone',
      icon: 'today',
      class: '',
      groupTitle: false,
      badge: '',
      badgeClass: '',
      role: ['Refferer'],
      submenu: [],
    },

  // Assitant Module

   {
    path: '/assitant/dashboard',
    title: 'Dashboard',
    moduleName: 'dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Assitant'],
    submenu: [],
  },
  // Doctor Modules
  {
    path: '/doctor/dashboard',
    title: 'Dashboard',
    moduleName: 'dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Doctor'],
    submenu: [],
  },
  {
    path: 'doctor/schedule',
    title: 'Clinic Schedule',
    moduleName: 'DoctorScheduleModule',
    iconType: 'material-icons-two-tone',
    icon: 'supervised_user_circle',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Doctor'],
    submenu: [],
  },
  // Common Modules


  {
    path: '/appointment/doctor',
    title: 'Appointment List',
    moduleName: 'ApointmentModule',
    iconType: 'material-icons-two-tone',
    icon: 'assignment',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Doctor'],
    submenu: [],
  },
  {
    path: '/doctor/settings',
    title: 'Settings',
    moduleName: 'DoctorModule',
    iconType: 'material-icons-two-tone',
    icon: 'settings',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Doctor'],
    submenu: [],
  },


  {
    path: '/appointment/list-all',
    title: 'Appointment List',
    moduleName: 'ApointmentModule',
    iconType: 'material-icons-two-tone',
    icon: 'assignment',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },{
    path: '/authentication/signin',
    title: 'Logout',
    moduleName: 'logout',
    iconType: 'material-icons-two-tone',
    icon: 'power_settings_new',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },
];
