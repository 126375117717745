import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
} from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "../../../environments/environment";

import {
  Appointment,
  AppointmentResponse,
} from "../../shared/interfaces/appointment.interface";

@Injectable({
  providedIn: "root",
})
export class ApointmentServiceService {
  constructor(private httpClient: HttpClient) {}

  AddNewAppointment(FormData) {
    return this.httpClient
      .post<AppointmentResponse>(environment.apiUrl + "appointment/addnew/", {
        FormData,
      })
      .pipe(
        map((Data) => {
          return Data;
        })
      );
  }

  AppointmentDetails(ApptId) {
    return this.httpClient
      .get<AppointmentResponse>(
        environment.apiUrl + "appointment/appointmentdetails/" + ApptId
      )
      .pipe(
        map((Data) => {
          return Data;
        })
      );
  }

  UpdateAppointment(ApptId, FormData) {
    return this.httpClient
      .post<boolean>(
        environment.apiUrl + "appointment/updateappointment/" + ApptId,
        { FormData }
      )
      .pipe(
        map((Data) => {
          return Data;
        })
      );
  }

  UploadAttachments(FormData, AptId) {
    return this.httpClient
      .post<boolean>(
        environment.apiUrl + "appointment/uploadAttachments/" + AptId,
        {
          FormData,
        }
      )
      .pipe(
        map((Data) => {
          return Data;
        })
      );
  }

  CancelAppointment(ApptId, cancel_reason, status) {
    return this.httpClient
      .post<boolean>(
        environment.apiUrl + "appointment/cancelstatus/" + ApptId,
        { cancel_reason, status }
      )
      .pipe(
        map((Data) => {
          return Data;
        })
      );
  }

  ChangeStatus(status, ApptId) {
    return this.httpClient
      .post<boolean>(
        environment.apiUrl + "appointment/changestatus/" + ApptId,
        { status }
      )
      .pipe(
        map((Data) => {
          return Data;
        })
      );
  }

  UpdateStatusAppointment(AppId, FormData) {
    return this.httpClient
      .post<boolean>(
        environment.apiUrl + "appointment/updatestatusappointment/" + AppId,
        { FormData }
      )
      .pipe(
        map((Data) => {
          return Data;
        })
      );
  }

  UrgentTextAppointment(ApptId) {
    return this.httpClient
      .get<AppointmentResponse>(
        environment.apiUrl + "appointment/urgentText/" + ApptId
      )
      .pipe(
        map((Data) => {
          return Data;
        })
      );
  }

  EmergencyCallAppointment(ApptId) {
    return this.httpClient
      .get<AppointmentResponse>(
        environment.apiUrl + "appointment/callUser/" + ApptId
      )
      .pipe(
        map((Data) => {
          return Data;
        })
      );
  }
}
