<div>
    <!-- Left Sidebar -->
    <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
        <!-- Menu -->
        <div class="menu">
            <ul class="list" [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}" [perfectScrollbar]>
                <li class="sidebar-user-panel">
                    <div class="user-panel">
                        <div class=" image">
                            <img src={{userImg}} class="img-circle user-img-circle" alt="User Image" />
                        </div>
                    </div>
                    <div class="profile-usertitle">
                        <div class="sidebar-userpic-name">{{userFullName}} </div>
                        <div class="profile-usertitle-job ">
                            <span *ngIf="userType !=='Refferer' && userType !== 'refferer' && userType !== 'Doctor' && userType !== 'doctor'">{{userType}}</span>
                            <span *ngIf="userType === 'Refferer' || userType === 'refferer'">Referring Doctor</span>

                            <span *ngIf="userType === 'Doctor' || userType === 'doctor'">Surgeon</span>
                        </div>
                    </div>
                </li>
                <!-- Top Most level menu -->
                <li [class.active]="showMenu === sidebarItem.moduleName" *ngFor="let sidebarItem of sidebarItems" [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'">
                    <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title}}</div>
                    <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;" [ngClass]="[sidebarItem.class]" (click)="callMenuToggle($event, sidebarItem.moduleName)">
                        <i [ngClass]="[sidebarItem.iconType]">{{sidebarItem.icon}}</i>
                        <span class="hide-menu">{{sidebarItem.title}}
            </span>
                        <span *ngIf="sidebarItem.badge != '' " [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
                    </a>
                    <!-- First level menu -->
                    <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
                        <li [ngClass]="showSubMenu === sidebarSubItem.moduleName? 'activeSub':''" *ngFor="let sidebarSubItem of sidebarItem.submenu" [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'">
                            <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]" (click)="callSubMenuToggle($event,sidebarSubItem.moduleName)" [ngClass]="[sidebarSubItem.class]">
                {{sidebarSubItem.title}}
              </a>
                            <!-- Second level menu -->
                            <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">
                                <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu" [ngClass]="showSubSubMenu === sidebarSubsubItem.moduleName? 'activeSubSub':''" [routerLinkActive]="sidebarSubsubItem.submenu.length > 0 ? '' : 'active'">
                                    <a [routerLink]="sidebarSubsubItem.submenu.length > 0 ? null : [sidebarSubsubItem.path]" [ngClass]="[sidebarSubsubItem.class]">
                    {{sidebarSubsubItem.title}}
                  </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
              <li>
                <div class="info-footer">
                  <div class="name">
                      <div>
                        <b>ORRCA Healthcare Solutions</b>
                      </div>
                      <div>
                        <b>Vancouver, BC</b>
                      </div>
                  </div>
                  <div class="email">
                    <div>For inquiries: <a href="mailto:info@orrca.ca">info@orrca.ca</a></div>
                  </div>
                  <div class="email">
                    <div>For support: <a href="mailto:support@orrca.ca">support@orrca.ca</a></div>
                  </div>
                </div>
              </li>
            </ul>
        </div>
        <!-- #Menu -->
    </aside>
    <!-- #END# Left Sidebar -->
</div>
